import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, { Suspense, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import { FloatButton, Modal } from "antd";
import { CustomerServiceOutlined } from "@ant-design/icons";
import { ThemeProvider } from "@material-ui/core";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useModal, useResize } from "hooks";
import GlobalStyles from "./components/Old/GlobalStyles";
import Loading from "./components/Old/Loading";

import theme from "./theme";
import routes from "./routes";

const App = () => {
  const routing = useRoutes(routes);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { handleResize, isMobile } = useResize();
  const { resetModal } = useModal();

  const handleClickHelpButton = () => {
    window.open("https://trialinformatics.atlassian.net/servicedesk/customer/portal/3");
  };

  const handlePopState = () => {
    Modal.destroyAll();
    if (!search.includes("isShowModal=true")) {
      resetModal();
    }
  };

  useEffect(() => {
    // 팝업창 띄우기
    if (window) {
      const hostname = window.location.hostname;
      if (hostname.includes("ti-otb")) {
        window.location.href = `https://${hostname.replace("ti-otb", "titdh")}${window.location.pathname}`;
      }
    }

    if (["prod"].includes(process.env.REACT_APP_ENV) && pathname.includes("new-")) navigate("/500");

    handleResize();
    handlePopState();
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Suspense fallback={<Loading />}>
        {routing}
        {!pathname.includes("edit") && !isMobile && <FloatButton icon={<CustomerServiceOutlined />} onClick={handleClickHelpButton} />}
      </Suspense>
      <ReactQueryDevtools initialIsOpsen />
    </ThemeProvider>
  );
};

export default App;
