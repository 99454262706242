import { create } from "zustand";

export const useBaseTableStore = create((set) => ({
  isShow: false,
  isFiltered: false,
  initFiltered: {
    searchValue: "",
    date: [null, null],
    status: ["Progress", "Complete"],
  },
  filtered: {
    searchValue: "",
    date: [null, null],
    status: ["Progress", "Complete"],
  },

  setIsShow: (props) => set((state) => ({ ...state, isShow: props })),
  setIsFiltered: (props) => set((state) => ({ ...state, isFiltered: props })),

  setSearchValue: (props) =>
    set((state) => ({
      ...state,
      filtered: { ...state.filtered, searchValue: props },
    })),
  setDate: (props) =>
    set((state) => ({
      ...state,
      filtered: { ...state.filtered, date: props },
    })),
  setStatus: (props) =>
    set((state) => ({
      ...state,
      filtered: { ...state.filtered, status: props },
    })),
  filter: (props) =>
    set((state) => ({
      ...state,
      filtered: props,
    })),

  resetBaseTableStore: () =>
    set((state) => ({
      ...state,
      type: null,
      isShow: false,
      isFiltered: false,
      filtered: state.initFiltered,
    })),
  resetDate: () =>
    set((state) => ({
      ...state,
      filtered: { ...state.filtered, date: state.initFiltered.date },
    })),
  resetFiltered: () =>
    set((state) => ({
      ...state,
      filtered: state.initFiltered,
    })),
}));
