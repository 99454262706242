import axios from "axios";
import qs from "qs";

import _localStorage from "./_localStorage";

const host = process.env.REACT_APP_API_SERVER_HOST;

const HTTP_METHOD = {
  GET: "get",
  POST: "POST",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
  OPTIONS: "options",
};

const backend = {};
backend.host = host;

backend.request = (method, _url, data, responseType, signed) => {
  let url = `${host}${_url}`;

  let config = {
    method,
    url,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token,withCredentials",
      "Content-Type": data instanceof FormData ? "multipart/form-data" : "application/json",
      // Accept: 'application/json',
    },
  };

  if (responseType) {
    config = { responseType: responseType, ...config };
  }

  if (signed) {
    config.headers = {
      "TI-DS-ID": signed.email,
      "TI-DS-PW": signed.password,
      ...config.headers,
    };
  }

  const accessToken = _localStorage.getItem("accessToken");
  if (accessToken) {
    axios.defaults.headers.common["X-AUTH-TOKEN"] = `${accessToken.token}`;
  }

  if (data) {
    if (config.method === HTTP_METHOD.GET) {
      url = url + (url.indexOf("?") > 0 ? "&" : "?") + qs.stringify(data);
      config.url = url;
    } else {
      config.data = data;
    }
  }

  const promise = axios(config);
  return new Promise((resolve, reject) => {
    promise
      .then((res) => {
        resolve(res.data);
      })
      .catch((error) => {
        if (url.includes("/user/this")) {
          alert("권한이 없습니다.");
          window.location.href = `/auth/login?redirect=${window.location.pathname}`;
        }
        backend.onError(error, reject);
        reject(error);
      });
  });
};

backend.get = (url, data) => {
  return backend.request(HTTP_METHOD.GET, url, data);
};

backend.getBlob = (url, data) => {
  return backend.request(HTTP_METHOD.GET, url, data, "blob");
};

backend.getDigitalSign = (url, data, sign) => {
  return backend.request(HTTP_METHOD.GET, url, data, null, sign);
};

backend.post = (url, data) => {
  return backend.request(HTTP_METHOD.POST, url, data);
};

backend.postBlob = (url, data) => {
  return backend.request(HTTP_METHOD.POST, url, data, "blob");
};

backend.postDigitalSign = (url, data, type, sign) => {
  return backend.request(HTTP_METHOD.POST, url, data, type, sign);
};

backend.patch = (url, data) => {
  return backend.request(HTTP_METHOD.PATCH, url, data);
};

backend.put = (url, data) => {
  return backend.request(HTTP_METHOD.PUT, url, data);
};

backend.putDigitalSign = (url, data, type, sign) => {
  return backend.request(HTTP_METHOD.PUT, url, data, type, sign);
};

backend.delete = (url, data) => {
  return backend.request(HTTP_METHOD.DELETE, url, data);
};

backend.delDigitalSign = (url, data, sign) => {
  return backend.request(HTTP_METHOD.DELETE, url, data, false, sign);
};

backend.options = (url, data) => {
  return backend.request(HTTP_METHOD.OPTIONS, url, data);
};

backend.onError = (e, callback) => {
  const err = e.response;

  if (err.data.status === 500) {
    err.data.statusText = "시스템 문제가 발생하였습니다. 관리자에게 문의하세요.";
    callback(err);
    return;
  } else if (err.data.status === 404) {
    // window.location.href = "/404";
    return;
  }

  if (err) {
    // console.log(err)MISMATCH_DS_ID_PW
    switch (err.data.code) {
      case "Unauthorized":
        break;
      case "MISMATCH_DS_ID_PW":
        err.data.statusText = "Digital Sign 의  ID 또는 PW 가 틀립니다.";
        callback(err);
        break;
      default:
        callback(err);
        break;
    }
  }
  // callback(err);
  // return;
};

export default backend;
