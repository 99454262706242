import { create } from "zustand";

export const useESignStore = create((set) => ({
  files: [],
  setFiles: (updater) =>
    set((state) => {
      const updatedFiles = typeof updater === "function" ? updater(state.files) : updater;
      return { ...state, files: updatedFiles };
    }),
}));
