import { useLocation, useNavigate } from "react-router-dom";
import { useDrawerStore } from "stores";

export const useDrawer = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const type = useDrawerStore((state) => state.type);
  const isShow = useDrawerStore((state) => state.isShow);
  const selectedData = useDrawerStore((state) => state.selectedData);
  const setType = useDrawerStore((state) => state.setType);
  const setIsShow = useDrawerStore((state) => state.setIsShow);
  const selectData = useDrawerStore((state) => state.selectData);

  const openDrawer = (data = null) => {
    navigate(pathname + "?isShowDrawer=true");
    setType(data?.type);
    setIsShow(true);
    selectData(data);
  };

  const closeDrawer = () => {
    setIsShow(false);
    selectData(null);
    setTimeout(() => {
      navigate(-1);
      setType(null);
    }, 300);
  };

  const resetDrawer = () => {
    setType(null);
    setIsShow(false);
    selectData(null);
  };

  const selectDataDrawer = (data) => {
    selectData(data);
  };

  return {
    typeDrawer: type,
    isShowDrawer: isShow,
    selectedDataDrawer: selectedData,
    openDrawer,
    closeDrawer,
    resetDrawer,
    selectDataDrawer,
  };
};
