import { useResizeStore } from 'stores';

export const useResize = (id, extraSize = { width: 0, height: 0 }) => {
  const resize = useResizeStore((state) => state.resize);
  const setResize = useResizeStore((state) => state.setResize);

  const handleResize = () => {
    if (id?.width) {
      const el = document.getElementById(id?.width);
      if (el) setResize({ width: el.clientWidth + extraSize.width });
    } else {
      setResize({ width: window.innerWidth + extraSize.width });
    }

    if (id?.height) {
      const el = document.getElementById(id?.height);
      if (el) setResize({ height: el.clientHeight + extraSize.height });
    } else {
      setResize({ height: window.innerHeight + extraSize.height });
    }
  };

  return {
    handleResize,
    width: resize.width,
    height: resize.height,
    xl: resize.width >= 1200,
    lg: resize.width < 1200 && resize.width >= 992,
    md: resize.width < 992 && resize.width >= 768,
    isMobile: resize.width < 768,
    sm: resize.width < 768 && resize.width >= 576,
    xs: resize.width < 576,
  };
};
