import { create } from "zustand";
import { persist } from "zustand/middleware";

export const useOldESignStore = create(
  persist(
    (set, get) => ({
      agreementId: "",
      projectId: "",
      name: "",
      message: "",
      files: [],
      users: [],
      emailOption: {},
      reminderFrequency: {},
      expirationTime: "",
      setESignPart: (props) => set((state) => ({ ...state, ...props })),
      setESignAll: ({ projectId, name, message, files, users, isOrder, isEmail, selectedFreq, expirationTime }) =>
        set((state) => ({
          ...state,
          projectId,
          name,
          message,
          files,
          users: users.map((user, userIdx) => {
            return {
              id: userIdx + 1,
              order: isOrder ? userIdx + 1 : 1,
              role: "SIGNER",
              email: user.email,
              name: user.name,
            };
          }),
          emailOption: isEmail
            ? {}
            : {
                emailOption: {
                  sendOptions: {
                    completionEmails: "NONE",
                    inFlightEmails: "NONE",
                    initEmails: "NONE",
                  },
                },
              },
          reminderFrequency:
            selectedFreq === "NONE"
              ? {}
              : {
                  reminderFrequency: selectedFreq,
                },
          expirationTime,
        })),
      getESignAdobeAgreementsBody: () => ({
        fileInfos: get().files.map(({ transientDocumentId }) => {
          return { transientDocumentId };
        }),
        participantSetsInfo: get().users.map((user) => {
          return {
            order: user.order,
            role: "SIGNER",
            memberInfos: [
              {
                email: user.email,
                name: user.name,
              },
            ],
          };
        }),
        name: get().name,
        message: get().message,
        signatureType: "ESIGN",
        state: "AUTHORING",
        ...get().emailOption,
        ...get().reminderFrequency,
        expirationTime: get().expirationTime,
      }),
      getESignAgreementsBody: () => ({
        projectId: get().projectId,
        agreementId: get().agreementId,
        name: get().name,
        message: get().message,
        signerInfoList: get().users.map((user) => {
          return {
            order: user.order,
            name: user.name,
            email: user.email,
            emailId: user.emailId,
          };
        }),
        expirationTime: get().expirationTime,
      }),
      isOrder: () => get().users.filter((user) => user.order === 2).length === 1,
      getSelectedUserIdx: (selectedUser) => get().users.findIndex((user) => selectedUser.email === user.email),
      setESignInitialize: () =>
        set(() => ({
          agreementId: "",
          projectId: "",
          name: "",
          message: "",
          files: [],
          users: [],
          emailOption: {},
          reminderFrequency: {},
          expirationTime: "",
        })),
    }),
    {
      name: "e-sign-edit2",
      getStorage: () => sessionStorage,
    },
  ),
);
