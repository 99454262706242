import { create } from "zustand";

export const useQuestionStore = create((set) => ({
  initSelected: {
    questionCategoryPks: [],
    regions: [],
    countries: [],
    attachmentFileExist: [],
    urgent: [],
  },
  selected: {
    questionCategoryPks: [],
    regions: [],
    countries: [],
    attachmentFileExist: [],
    urgent: [],
  },

  select: (props) =>
    set((state) => ({
      ...state,
      selected: props,
    })),

  resetQuestionStore: () =>
    set((state) => ({
      ...state,
      selected: state.initSelected,
    })),
  resetSelected: () =>
    set((state) => ({
      ...state,
      selected: state.initSelected,
    })),
}));
